import React from 'react'

// Components
import GravityForm from 'components/shared/GravityForm'
import ParseContent from 'components/shared/ParseContent'

// styled importeren onder react
import styled from 'styled-components'

interface FormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const SectionWrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.secondary};
`

const FormWrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.color.face.light};
  overflow: hidden;

  & button {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  @media (min-width: 576px) {
    bottom: 110px;
    padding: 2rem 4.5rem;
    border-radius: 24px;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.36);
  }

  @media (max-width: 992px) {
    bottom: 0;
    padding: 1rem;
    border-radius: unset;
    box-shadow: unset;
    width: 100%;
  }
`

const Content = styled(ParseContent)`
  h3 {
    font-size: ${(props) => props.theme.font.size[32]};
  }

  @media screen and (max-width: 991px) {
    & h3 {
      line-height: 30px;
    }
  }
`

const Form: React.FC<FormProps> = ({ fields }) => (
  <SectionWrapper>
    <div className="container-lg">
      <div className="row">
        <FormWrapper className="col-lg-10 col-12 pb-5 mx-auto pt-lg-0 pt-5">
          <Content
            content={fields.description}
            className="py-2 col-md-8 col-12 pt-lg-5 pl-0"
          />
          <GravityForm id={fields.formId || 1} />
        </FormWrapper>
      </div>
    </div>
  </SectionWrapper>
)

export default Form
